import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faSpotify,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

// const IconLink = tw.a`
// text-3xl text-gray-300 hover:text-white
// `

export const IconLink = ({ href, icon, title }) => (
  <a
    className="text-3xl text-gray-300 hover:text-white"
    href={href}
    title={title}
  >
    <FontAwesomeIcon icon={icon} />
  </a>
)

const SocialLinks = ({ listClass }) => (
  <ul className={listClass || "flex space-x-4 items-center"}>
    <li>
      <IconLink
        href="https://www.linkedin.com/in/brad-carson-396b1934/"
        icon={faLinkedin}
        title="LinkedIn"
      />
    </li>
    <li>
      <IconLink
        href="https://www.instagram.com/bradcarson80/"
        icon={faInstagram}
        title="Instagram"
      />
    </li>
    <li>
      <IconLink
        href="https://www.linkedin.com/in/brad-carson-396b1934/"
        icon={faGithub}
        title="GitHub"
      />
    </li>
    <li>
      <IconLink
        href="https://open.spotify.com/user/1271829250"
        icon={faSpotify}
        title="Spotify"
      />
    </li>
    <li>
      <IconLink href="mailto:bradcarson@gmail.com" icon={faEnvelope} />
    </li>
  </ul>
)

export default SocialLinks
